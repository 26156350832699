<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p"></v-card>

    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
    </div>

    <FilterBox
      :open.sync="isFilterOpen"
      v-model="filter"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />

    <SizeBox height="24" />

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-spacer></v-spacer>
        <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

        <SizeBox width="14" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          sheet-name="sheetName"
        />
      </v-card-text>
      <DataTable
        :list="result.list"
        :fields="headers"
        :total="result.total"
        :loading="isLoading"
        :replace="['capital_log_type.key', 'updated_at|formatTime']"
        @get-list="fetchData"
      >
        <template #item.capital_log_type.key="{ item }">
          <div>{{ getCaitalLogName(item.capital_log_type.key) }}</div>
        </template>
      </DataTable>
    </v-card>
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import { getCollectOrdersLeaderboard } from '@/api/capitalLogs';

const capitalLogTypeMaps = [
  { text: '交易訂單', value: 'collect_order' },
  { text: '手動增加', value: 'manual_add' },
  { text: '手動減少', value: 'manual_reduce' },
  { text: '商戶結算', value: 'merchant_pay' },
  { text: 'API 代付', value: 'api_pay' },
  { text: '駁回商戶結算', value: 'cancel_merchant_pay' },
  { text: '駁回 API 代付', value: 'cancel_api_pay' },
  { text: '凍結', value: 'freeze' },
  { text: '解凍', value: 'unfreeze' },
  { text: '提成', value: 'commission' },
  { text: '扣除 API 代付手續費', value: 'api_pay_fee' },
  { text: '退回 API 代付手續費', value: 'cancel_api_pay_fee' },
  { text: '扣除商戶結算手續費', value: 'merchant_pay_fee' },
  { text: '退回商戶結算手續費', value: 'cancel_merchant_pay_fee' }
];

export default {
  async created() {
    this.fetchData();
  },

  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        {
          text: '商戶編號',
          value: 'merchant.serial',
          align: 'center'
        },
        {
          text: '商戶名稱',
          value: 'merchant.name',
          align: 'center'
        },
        {
          text: '儲值金額',
          value: 'amount',
          align: 'center'
        }
      ],
      filterOptions: [
        {
          label: '成功時間',
          type: 'timeSet',
          name: 'succeeded_at',
          range: true,
          withTime: true
        }
      ]
    };
  },

  computed: {},

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;

      const CapitalLogs = await getCollectOrdersLeaderboard({
        ...condition,
        ...this.filter
      });

      if (!CapitalLogs.error) {
        this.result.total = CapitalLogs.total;
        this.result.page = CapitalLogs.page;
        this.$set(
          this.result,
          'list',
          CapitalLogs.items.map((item) => ({
            ...item
          }))
        );
      }

      this.isLoading = false;
    },
    getCaitalLogName(key) {
      const capitalLogType = capitalLogTypeMaps.find((o) => o.value === key);
      if (capitalLogType) return capitalLogType.text;
      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
