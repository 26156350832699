import { api } from '.';

export function getFrozenOrders(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/capitalLogs/getFrozenOrders', payload);
}

export function getCapitalLogs(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/capitalLogs/getCapitalLogs', payload);
}

export function unfreezeFrozenOrder(payload) {
  return api('/v1/capitalLogs/unfreezeFrozenOrder', payload);
}

export function getCapitalLogTypes() {
  return api('/v1/capitalLogTypes/getCapitalLogTypes');
}

export function editFrozenOrderNote(payload) {
  return api('/v1/capitalLogs/editFrozenOrderNote', payload);
}

export function getCollectOrdersLeaderboard(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/capitalLogs/getCollectOrdersLeaderboard', payload);
}
